import { Flex, Text } from "@chakra-ui/react";

const AboutMeText = ({ isLargerThan800, gapValues }) => {


    return (
        <Flex 
        flexDir={"column"} 
        align={"center"} 
        justify={"center"} 
        width={isLargerThan800 ? "35%" : "90%"}
        gap={"32px"} 
        mt={isLargerThan800 ? gapValues : "50px"}>

            <Text
                fontFamily={"Lexend"}
                fontSize={"30px"}
                fontWeight={"300"}
                color={"#37373F"}
                letterSpacing={"-2px"}
                textAlign={!isLargerThan800 && "center"}
            >
                I'm Milad, a Software Developer Blending Creativity and Technology in Melbourne, Australia.
            </Text>
            <Text
                fontFamily={"Lexend"}
                fontSize={"20px"}
                fontWeight={"200"}
                color={"#37373F"}
                lineHeight={"normal"}
                letterSpacing={"0.22px"}
                textAlign={!isLargerThan800 && "center"}
            >
                Welcome to my corner of the internet! I'm a <b>Melbourne-based</b> Software Developer hoping to further my career within the tech sector. My journey in tech started with a curious mind and passion for all things design, leading me to a B.Sc in Computer Science & Software Systems from the esteemed <b>University of Melbourne.</b>
                <br />
                <br />
                When I'm not busy debugging or deploying, you might find me exploring the latest in UI design, tinkering with new projects, or delving deeper into my passion for <b>front-end development</b> and art!
                <br />
                <br />
                Dive into my page to discover more about my current and future projects, skills, and more! Let's connect and create something amazing together!
            </Text>
        </Flex>
    );
}

export default AboutMeText;