import { useMediaQuery, Flex, Link } from "@chakra-ui/react";
import gsap from "gsap";
import { useEffect } from "react";
import ShowcaseImage from "../UI/ShowcaseImage";
import ReactProjectText from "./ReactProjectText";
import HoverButton from "../UI/HoverButton";

const AboutMeReactProject = () => {

    const [isLargerThan800] = useMediaQuery('(min-width: 800px)');

    useEffect(() => {

        let tl = gsap.timeline({
            defaults: {
                immediateRender: false
            },
            scrollTrigger: {
                trigger: ".text-element",
                start: "top 95%",
                end: "center center",
                scrub: true
            },

        });

        tl.from(".text-element", {
            duration: 1,
            opacity: 0,
            x: 200
        });


        return () => {
            tl.kill();
        };

    }, [])

    return (
        <Flex
            display={"inline-flex"}
            flexDir={"row"}
            justify={"center"}
            align={"center"}
            width={"100vw"}
            height={"100vh"}
            gap={"75px"}
        >

            {
                isLargerThan800 &&
                <ShowcaseImage src={"images/react-project.png"} href={"https://github.com/mizra2/project-izra"} about={true} />
            }
            <Flex flexDir={"column"} justify={"center"} align={"center"} className="text-element" mx={!isLargerThan800 && 4}>

                <ReactProjectText isLargerThan800={isLargerThan800} />
                <Flex width={isLargerThan800 && "100%"} align={isLargerThan800 ? "flex-start" : "center"}>
                    <Link width={"100%"} href="https://github.com/mizra2/project-izra" isExternal>
                        <HoverButton styling={{ width: isLargerThan800 ? "30%" : "100%" }} text={"Coming Soon"}/>
                    </Link>
                </Flex>
            </Flex>
        </Flex >
    );

}

export default AboutMeReactProject;
