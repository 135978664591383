import { Flex, Text } from "@chakra-ui/react";
import { useMediaQuery } from "@chakra-ui/react";

const Footer = () => {
    const [isLargerThan800] = useMediaQuery('(min-width: 800px)')
    return (<Flex
        display={"block"}
        flexDir={"column"}
        justify={"center"}
        align={"center"}
        height={"45vh"}
        width={"100vw"}
        py={"80px"}
        p={6}
    >
        <Flex margin={"0 auto"} maxWidth={"1250px"} flexDir={"column"} gap={"10px"} pt={"30px"} borderTop={"2px solid #37373F"}>
            <Text
                fontFamily={"Lexend"}
                fontSize={"26px"}
                fontWeight={"800"}
                color={"#37373F"}
                letterSpacing={"-2px"}
            >
                miladizra.dev@gmail.com
            </Text>
            {
                isLargerThan800 ? (<Text
                    fontFamily={"Lexend"}
                    fontSize={"20px"}
                    fontWeight={"200"}
                    color={"#37373F"}
                    lineHeight={"normal"}
                    letterSpacing={"0.22px"}
                >
                    I'm always open to collaboration whenever I have the opportunity. <br />
                    Hoping to advance my career by bringing innovative changes to the tech sector. <br />
                    Let's connect and explore possibilities together.
                </Text>) : (<Text
                    fontFamily={"Lexend"}
                    fontSize={"20px"}
                    fontWeight={"200"}
                    color={"#37373F"}
                    lineHeight={"normal"}
                    letterSpacing={"0.22px"}
                >
                    I'm always open to collaboration whenever I have the opportunity.
                    Hoping to advance my career by bringing innovative changes to the tech sector.
                    Let's connect and explore possibilities together.
                </Text>)
            }
        </Flex>
        <Flex margin={"0 auto"} maxWidth={"1250px"} flexDir={"column"} gap={"25px"} mt={"50px"} lineHeight={"1.5"} >
            <Text
                fontFamily={"Lexend"}
                fontSize={"14px"}
                fontWeight={"200"}
                color={"#37373F"}
            >
                Copyright © Milad Izra.
                <br>
                </br>
                Illustrations courtesy of Transhumans by Pablo Stanley.
            </Text>
        </Flex>


    </Flex>)
}

export default Footer;
