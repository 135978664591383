import { Flex } from "@chakra-ui/react";
import AboutMeImg from "../UI/AboutMeImg";
import { useMediaQuery } from "@chakra-ui/react";
import AboutMeText from "./AboutMeText";
const AboutMePageContent = () => {


    const gapValues = {
        base: "600px",
        sm: "500px",
        md: "400px",
        lg: "250px",
        xl: "100px",
        "2xl": "0px"
    };

    const gapValues2 = {
        base: "0px",
        sm: "50px",
        md: "150px",
        lg: "125px",
        xl: "100px",
        "2xl": "0px"
    };

    const [isLargerThan800] = useMediaQuery('(min-width: 800px)')


    return (
        <Flex
            display={"inline-flex"}
            flexDir={"row"}
            gap={"40px"}
            justify={"center"}
            align={"center"}
            width={"100vw"}
            height={isLargerThan800 ? "100vh" : "110vh"}
        >
            {
                isLargerThan800 && <Flex align={"center"} justify={"center"} mt={gapValues2}>
                    <AboutMeImg />
                </Flex>
            }
            <AboutMeText gapValues={gapValues} isLargerThan800={isLargerThan800}/>
        </Flex>
    )
}

export default AboutMePageContent;