import { Text, Box } from "@chakra-ui/react";
const ReactProjectText = ({ isLargerThan800 }) => {

    return (
        <Box>
            <Text
                fontFamily={"Lexend"}
                fontSize={"32px"}
                fontWeight={"600"}
                color={"#37373F"}
                letterSpacing={"-0.48px"}
                textAlign={!isLargerThan800 && "center"}
            >
                Interactive React Portfolio
            </Text>
            <Text
                fontSize={"20px"}
                fontFamily={"Lexend"}
                fontWeight={"200"}
                maxW={"540px"}
                marginTop={"10px"}
                display={"block"}
                marginBlockStart={"1em"}
                marginBlockEnd={"1em"}
                marginInlineStart={"0px"}
                marginInlineEnd={"0px"}
                color={"#37373F"}
                lineHeight={"1.75"}
                letterSpacing={"0.2px"}
                textAlign={!isLargerThan800 && "center"}
            >
                A simple and minimalistic portfolio crafted using React, showcasing a sleek design with intuitive navigation,
                highlighting professional accomplishments and projects with elegance and efficiency.
            </Text>
        </Box>
    );

}

export default ReactProjectText;