import { Flex, Box, Text, Link } from "@chakra-ui/react";
import { useNavigate } from "react-router";
const Header = () => {

    const nav = useNavigate();

    return (
        <Flex
            as="header"
            align="center"
            justify={"space-between"}
            position={"fixed"}
            alignItems={"center"}
            width={"100%"}
            top={0}
            borderBottom={"2px solid #37373F"}
            background="rgba(239, 237, 235, 0.9)"
            blue
            height={"120px"}
            zIndex={12}
            backdropFilter="blur(10px)"
            
        >
            <Box marginLeft={"105px"} >
                <Text
                    color={"#37373F"}
                    fontFamily={"Lexend"}
                    fontSize={"24px"}
                    fontStyle={"normal"}
                    fontWeight={400}
                    lineHeight={"normal"}
                    letterSpacing={"-0.48px"}
                    cursor={"pointer"}
                    onClick={() => {
                        nav('')
                    }}

                >
                    milad izra
                </Text>
            </Box>

            <Box display={"flex"} gap={"48px"} marginRight={"105px"} >
                <Text
                    color={"#37373F"}
                    fontFamily={"Lexend"}
                    fontSize={"24px"}
                    fontStyle={"normal"}
                    fontWeight={"400"}
                    lineHeight={"normal"}
                    letterSpacing={"-0.48px"}
                    cursor={"pointer"}
                    onClick={() => {
                        nav('/about')
                    }}

                >
                    about
                </Text>
                <Link _hover={{ borderBottom: "none" }} href={"https://github.com/mizra2"} target="_blank" rel="noreferrer">
                    <Text
                        color={"#37373F"}
                        fontFamily={"Lexend"}
                        fontSize={"24px"}
                        fontStyle={"normal"}
                        fontWeight={"400"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.48px"}
                        cursor={"pointer"}

                    >
                        github
                    </Text>
                </Link>
                <Text
                    color={"#37373F"}
                    fontFamily={"Lexend"}
                    fontSize={"24px"}
                    fontStyle={"normal"}
                    fontWeight={"400"}
                    lineHeight={"normal"}
                    letterSpacing={"-0.48px"}
                    cursor={"pointer"}
                    onClick={() => {
                        nav('/contact')
                    }}
                >
                    contact
                </Text>
            </Box>
        </Flex>
    )

}



export default Header;