import { Flex, VStack, FormControl, FormLabel, Input, FormErrorMessage, Button, Textarea } from "@chakra-ui/react";
import { Formik, Field } from "formik";

const ContactForm = ({ isLargerThan800, sendEmail }) => {

    return (
        <Flex
            align={"center"}
            justify={"center"}
            h={"100%"}
            w={isLargerThan800 ? "70vh" : "45vh"}
            p={isLargerThan800 ? "5px" : "15px"}
        >
            <Formik
                initialValues={{
                    name: "",
                    email: "",
                    message: ""
                }}
                onSubmit={sendEmail}
                width="100%"
            >
                {({ handleSubmit, errors, touched }) => (
                    <form style={{ width: "95%", paddingTop: "90px", alignContent: "center", justifyContent: "center" }} onSubmit={handleSubmit}>
                        <VStack spacing={10} >

                            {/* Name field */}
                            <FormControl width={"100%"} isInvalid={!!errors.name && touched.name}>
                                <FormLabel fontFamily={"Lexend"} fontSize={"24px"} color={"#37373F"} htmlFor="name">Name</FormLabel>
                                <Field
                                    as={Input}
                                    id="name"
                                    name="name"
                                    type="name"
                                    variant="flushed"
                                    color=""
                                    style={{ borderBottom: "2px solid #37373F" }}
                                    placeholder="Name"
                                    width="100%"
                                    validate={(value) => {
                                        let error;
                                        if (value?.length <= 0 || !value) {
                                            error = "Please enter your name first!";
                                        }
                                        return error;
                                    }}
                                />
                                <FormErrorMessage>{errors.name}</FormErrorMessage>
                            </FormControl>

                            {/* Email field */}
                            <FormControl isInvalid={!!errors.email && touched.email}>
                                <FormLabel fontFamily={"Lexend"} fontSize={"24px"} color={"#37373F"} htmlFor="email">E-Mail</FormLabel>
                                <Field
                                    as={Input}
                                    id="email"
                                    name="email"
                                    type="email"
                                    variant="flushed"
                                    style={{ borderBottom: "2px solid #37373F" }}
                                    placeholder="E-Mail"
                                    validate={(value) => {
                                        let error;
                                        if (value?.length <= 0 || !value) {
                                            error = "Please enter a valid email!";
                                        }
                                        return error;
                                    }}
                                />
                                <FormErrorMessage>{errors.email}</FormErrorMessage>
                            </FormControl>
                            {/* Message Field */}
                            <FormControl isInvalid={!!errors.message && touched.message}>
                                <FormLabel fontFamily={"Lexend"} fontSize={"24px"} color={"#37373F"} htmlFor="message">Message</FormLabel>
                                <Field
                                    as={Textarea}
                                    id="message"
                                    name="message"
                                    type="message"
                                    variant="flushed"
                                    style={{ borderBottom: "2px solid #37373F" }}
                                    placeholder="Message"
                                    height="15vh"
                                    validate={(value) => {
                                        let error;
                                        if (value?.length <= 0 || !value) {
                                            error = "Please enter a valid message!";
                                        }
                                        return error;
                                    }}
                                />
                                <FormErrorMessage>{errors.message}</FormErrorMessage>
                            </FormControl>

                            {/* Submit button */}
                            <Button
                                type="submit"
                                color={"white"}
                                bg={"#FE854E"}
                                width={"100%"}
                            >
                                Send Message
                            </Button>
                        </VStack>
                    </form>
                )}
            </Formik>
        </Flex>
    );

}

export default ContactForm;