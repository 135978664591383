import AboutMePageContent from "../components/AboutPage/AboutMePageContent";
import { useEffect } from "react";
import AboutMeReactProject from "../components/AboutPage/AboutMeReactProject";
import FeaturedProjct from "../components/HomePage/FeaturedProject";
import { useMediaQuery } from "@chakra-ui/react";
import { Flex } from "@chakra-ui/react";

const AboutPage = () => {

    const [isLargerThan800] = useMediaQuery('(min-width: 800px)')
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return <Flex flexDir={"column"}>
        <AboutMePageContent />
        <FeaturedProjct isLargerThan800={isLargerThan800} about={true} />
        <AboutMeReactProject />
    </Flex>

}

export default AboutPage;