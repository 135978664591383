import { Flex } from "@chakra-ui/react";
import { useMediaQuery } from "@chakra-ui/react";
import ContactPageText from "./ContactPageText";
import ContactForm from "./ContactForm";
import useEmailSend from "../hooks/useEmailSend";

const ContactPageContent = () => {

    const [isLargerThan800] = useMediaQuery('(min-width: 800px)')

    const [sendEmail] = useEmailSend();

    return (
        <Flex
            flexDir={isLargerThan800 ? "row" : "column"}
            gap={isLargerThan800 ? "15vh" : ""}
            align={"center"}
            width={"100vw"}
            height={isLargerThan800 ? "100vh" : "125vh"}
            mt={isLargerThan800 ? "" : "200px"}
            justify={"center"}>

            <ContactPageText />

            <ContactForm isLargerThan800={isLargerThan800} sendEmail={sendEmail} />
        </Flex>
    )

}

export default ContactPageContent;