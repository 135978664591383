import { Box, Text } from "@chakra-ui/react";
const FeaturedProjectText = ({ isLargerThan800 }) => {
    return (
        <Box pt={"75px"}>
            <Text
                fontFamily={"Lexend"}
                fontSize={"32px"}
                fontWeight={"600"}
                color={"#37373F"}
                letterSpacing={"-0.48px"}
                textAlign={!isLargerThan800 && "center"}
            >
                Flemington Kensington News

            </Text>
            <Text
                fontSize={"20px"}
                fontFamily={"Lexend"}
                fontWeight={"200"}
                maxW={"540px"}
                marginTop={"10px"}
                display={"block"}
                marginBlockStart={"1em"}
                marginBlockEnd={"1em"}
                marginInlineStart={"0px"}
                marginInlineEnd={"0px"}
                color={"#37373F"}
                lineHeight={"1.75"}
                letterSpacing={"0.2px"}
                textAlign={!isLargerThan800 && "center"}
            >
                High-end and user-friendly tool is developed to streamline the
                process of tagging PDF documents and enhancing their accessibility.

            </Text>
        </Box>
    )

}

export default FeaturedProjectText;