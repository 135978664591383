import { useState } from "react";
import { Image, Box, Link } from "@chakra-ui/react";
const ShowcaseImage = ({ href, src, about }) => {

    const [imgHovered, setImgHovered] = useState(false);

    const handleMouseEnterImg = () => {
        setImgHovered(true);
    }

    const handleMouseLeaveImg = () => {
        setImgHovered(false);
    }

    return (
        <Link width={about ? "40%" : "40%"} href={href} isExternal>
            <Box width={"100%"} className="img-element">
                <Image
                    src={src}
                    width={"100%"}
                    height={"auto"}
                    transform={imgHovered ? "scale(1.05)" : "scale(1)"}
                    transition="transform 0.5s ease"
                    onMouseEnter={handleMouseEnterImg}
                    onMouseLeave={handleMouseLeaveImg}
                    minWidth={"550px"}
                    className="img-element"
                    boxShadow={about && "0px 2px 2px 0px rgba(0, 0, 0, 0.25)"}
                    borderRadius={about && "30px"}
                />
            </Box>
        </Link>)


}

export default ShowcaseImage;