import { Outlet } from "react-router";
import Header from "../components/UI/Header";
import { useMediaQuery } from "@chakra-ui/react";
import MobileHeader from "../components/UI/MobileHeader";
import Footer from "../components/UI/Footer";
const RootLayout = () => {
    const [isLargerThan800] = useMediaQuery('(min-width: 800px)');

    return (
        <>
            {isLargerThan800 ? <Header /> : <MobileHeader/>}
            <Outlet />
            <Footer/>
        </>
    )
}
export default RootLayout;