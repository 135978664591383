import { Flex, Link } from "@chakra-ui/react";
import { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import FeaturedProjectTitle from "./FeaturedProjectTitle";
import FeaturedProjectText from "./FeaturedProjectText";
import HoverButton from "../UI/HoverButton";
import ShowcaseImage from "../UI/ShowcaseImage";
gsap.registerPlugin(ScrollTrigger);

const FeaturedProjct = ({ scrollRef, isLargerThan800, about }) => {

    useEffect(() => {

        let tl = gsap.timeline({
            defaults: {
                immediateRender: false
            },
            scrollTrigger: {
                trigger: ".text-element2",
                start: "top 90%",
                end: "center center",
                scrub: true
            },

        });

        tl.from(".text-element2", {
            duration: 1,
            opacity: 0,
            x: -200
        });


        return () => {
            tl.kill();
        };

    }, [])

    return (
        <Flex
            display={"inline-flex"}
            flexDir={"row"}
            gap={"50px"}
            justify={"center"}
            align={"center"}
            width={"100vw"}
            height={"100vh"}
            ref={scrollRef}
        >
            <Flex flexDir={"column"} justify={"center"} align={"center"} className="text-element2" mx={!isLargerThan800 && 4}>
                <FeaturedProjectTitle isLargerThan800={isLargerThan800} about={about}/>
                <FeaturedProjectText isLargerThan800={isLargerThan800}/>

                <Flex width={isLargerThan800 && "100%"} align={isLargerThan800 ? "flex-start" : "center" }>
                    <Link width={"100%"} href="https://github.com/mizra2/pdf-tagging-tool" isExternal>
                        <HoverButton styling={{ width: isLargerThan800 ? "30%" : "100%" }} text={"Learn more"} />
                    </Link>
                </Flex>
            </Flex>
            {
                isLargerThan800 &&
                <ShowcaseImage href={"https://github.com/mizra2/pdf-tagging-tool"} src={"images/featured-project.png"}/>
            }
        </Flex >)

}

export default FeaturedProjct;