import { Flex } from "@chakra-ui/react";
import CodeBubble from "./CodeBubble";
import CodeBubbleMobile from "./CodeBubbleMobile";
import HomeText from "./HomeText";

const ShowcaseBox = ({ scrollHandler, isLargerThan800 }) => {

    return (
        <Flex
            display={"inline-flex"}
            gap={"0px"}
            justify={"center"}
            align={"center"}
            width={"100vw"}
            height={"100vh"}
        >
            <Flex
                flexDirection="column"
                align={"center"}
                justify={"center"}
            >
                {
                    isLargerThan800 ? <CodeBubble /> : <CodeBubbleMobile/>
                }
                <HomeText scrollHandler={scrollHandler} />
            </Flex>
            
        </Flex>
    );

}

export default ShowcaseBox;