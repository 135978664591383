import './App.css';
import { createBrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { RouterProvider } from 'react-router-dom';
import RootLayout from './pages/RootLayout';
import ContactPage from './pages/ContactPage';
import AboutPage from './pages/AboutPage';
import HomePage from './pages/HomePage';
import emailjs from 'emailjs-com';
import { useEffect } from 'react';
import WebFont from 'webfontloader';


const router = createBrowserRouter([{

	element: <RootLayout />,
	path: "/",
	children: [
		{
			index: true,
			element: <HomePage />
		},
		{
			path: "/about",
			element: <AboutPage />
		},
		{
			path: "/contact",
			element: <ContactPage />
		}
	]

}])

function App() {

	useEffect(() => {

		WebFont.load({
			google: {
				families: ['Lexend:100,200,300,400,500,600,700,800']
			}
		})
	}, [])



	
	document.body.style.backgroundColor = '#efedeb';
	emailjs.init(process.env.REACT_APP_EMAILJS_USER_ID);

	return (
		<ChakraProvider>
			<RouterProvider router={router} />
		</ChakraProvider>
	)
}

export default App;
