import { Flex, Box, Text, Drawer, DrawerOverlay, DrawerContent, DrawerHeader, DrawerBody, DrawerFooter, Link } from "@chakra-ui/react";
import { MdTableRows } from "react-icons/md";
import { useDisclosure } from "@chakra-ui/react";
import { useNavigate } from "react-router";
const MobileHeader = () => {

    const { isOpen, onOpen, onClose } = useDisclosure();
    const nav = useNavigate();

    return (
        <div>

            <Drawer placement={"left"} onClose={onClose} isOpen={isOpen}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerHeader
                        borderBottom={"1px solid rgba(55, 55, 63, 0.9)"}
                        textAlign="center" 
                        fontFamily={"Lexend"}
                        fontWeight={"200"}
                        letterSpacing={"4px"}
                        fontSize={"30px"}
                        color={"rgba(55, 55, 63, 0.9)"}
                        textShadow={"2px 2px rgba(55, 55, 63, 0.1)"}
                        mx={"16px"}
                    > MILAD IZRA
                    </DrawerHeader>
                    <DrawerBody alignContent={"center"} justifyContent={"center"} >
                        <Flex width={"100%"} height={"70%"} flexDir={"column"} gap={"50px"} justify={"center"} align={"center"}>
                            <Text cursor={"pointer"} fontFamily={"Lexend"} fontWeight={"200"} fontSize={"24px"} onClick={() => {
                                nav('/');
                                onClose();
                            }}>
                                Home
                            </Text>
                            <Text cursor={"pointer"} fontFamily={"Lexend"} fontWeight={"200"} fontSize={"24px"} onClick={() => {
                                nav('/about');
                                onClose();
                            }}>
                                About Me
                            </Text>
                            <Link _hover={{ borderBottom: "none" }} href={"https://github.com/mizra2"} target="_blank" rel="noreferrer" onClick={onClose}>
                                <Text cursor={"pointer"} fontFamily={"Lexend"} fontWeight={"200"} fontSize={"24px"}>
                                    GitHub
                                </Text>
                            </Link>
                            <Text cursor={"pointer"} fontFamily={"Lexend"} fontWeight={"200"} fontSize={"24px"} onClick={() => {
                                nav('/contact');
                                onClose();
                            }}>
                                Contact Me
                            </Text>
                        </Flex>
                    </DrawerBody>
                    <DrawerFooter mx={"14px"} borderTop={"1px solid rgba(55, 55, 63, 0.9)"} >
                        <Text
                            fontFamily={"Lexend"}
                            fontSize={"14px"}
                            fontWeight={"200"}
                            color={"#37373F"}
                            textAlign={"center"}
                        >
                            Copyright © Milad Izra.
                            <br />
                            Illustrations courtesy of Transhumans by Pablo Stanley.
                        </Text>

                    </DrawerFooter>
                </DrawerContent>
            </Drawer>

            <Flex
                as="header"
                align="center"
                justify={"space-between"}
                position={"fixed"}
                width={"100%"}
                top={0}
                borderBottom={"2px solid #37373F"}
                background="rgba(239, 237, 235, 0.9)"
                backdropFilter="blur(10px)"
                height={"90px"}
                zIndex={12}
                flexDir={"row"}
                paddingX={"40px"}
            >
                <MdTableRows size={"32px"} color="#37373F" onClick={onOpen} />
                <Text
                    textAlign="center" 
                    fontFamily={"Lexend"}
                    fontWeight={"200"}
                    letterSpacing={"4px"}
                    fontSize={"32px"}
                    color={"#37373F"}
                    textShadow={"2px 2px rgba(55, 55, 63, 0.1)"}
                >
                </Text>
                <Box width="14px" />
            </Flex>
        </div>)

}

export default MobileHeader;

