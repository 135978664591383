import { useState } from "react";
import { Button } from "@chakra-ui/react";

const HoverButton = ({ styling, onClick, text }) => {

    const [hovered, setHovered] = useState(false);

    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };


    return (
        <Button
            css={styling}
            height={"50px"}
            variant={"ghost"}
            fontFamily={"Lexend"}
            fontWeight={600}
            borderRadius={0}
            fontSize={"18px"}
            color={"#37373F"}
            p={"30px 0px"}
            position="relative"
            overflow="hidden"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            _before={{
                content: '""',
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "3px", 
                background: "#37373F",
                transform: hovered ? "scaleY(4)" : "scaleY(1)", 
                transformOrigin: "bottom",
                transition: "transform 0.25s ease-in-out",
            }}
            _hover={{ background: "transparent" }}
            onClick={onClick}
        >
            {text}
        </Button>)
}

export default HoverButton;