import emailjs from 'emailjs-com';
import { useToast } from '@chakra-ui/react';

const useEmailSend = () => {

    const toast = useToast();

    const sendEmail = (values, { resetForm }) => {

        const templateParams = {
            name: values.name,
            email: values.email,
            message: values.message,
        };

        const emailPromise = emailjs.send(
            process.env.REACT_APP_EMAILJS_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
            templateParams,
            process.env.REACT_APP_EMAILJS_USER_ID
        );

        toast.promise(emailPromise, {
            success: { title: 'Message Sent!', description: '' },
            error: { title: 'Sending Message Failed', description: 'Something went wrong!' },
            loading: { title: 'Sending Message', description: 'Please wait!' },
        });

        emailPromise
            .then(() => {
                resetForm();
            })
            .catch(() => {
                resetForm();
            });
    };

    return [sendEmail];
}

export default useEmailSend;