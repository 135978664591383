import { Flex, Box, Text } from "@chakra-ui/react";
import HoverButton from "../UI/HoverButton";
import { useEffect } from "react";
import { useState } from "react";

const HomeText = ({ scrollHandler }) => {

    const fullText = "Versatile Software Developer based in Melbourne.";
    const [typewriterText, setTypewriterText] = useState("");
    const [showCursor, setShowCursor] = useState(true);

    useEffect(() => {
        if (typewriterText.length < fullText.length) {
            const timeoutId = setTimeout(() => {
                setTypewriterText(fullText.substring(0, typewriterText.length + 1));
            }, 70); // Typing speed
            return () => clearTimeout(timeoutId);
        } else {
            // Blinker
            const cursorInterval = setInterval(() => {
                setShowCursor((show) => !show);
            }, 500); // Blinking speed
            return () => clearInterval(cursorInterval);
        }
    }, [typewriterText, fullText]);

    return (
        <Flex flexDir={"column"} width={"100%"} align={"center"} justify={"center"}>
            <Box>
                <Text
                    color={"#37373F"}
                    fontFamily={"Lexend"}
                    fontSize={"10vh"}
                    fontStyle={"normal"}
                    fontWeight={"500"}
                    lineHeight={"normal"}
                    letterSpacing={"-2px"}
                    justifyContent={"center"}
                    textAlign={"center"}
                >
                    Hi, I'm Milad.
                </Text>
            </Box>
            <Box>
                <Text
                    color={"#37373F"}
                    fontFamily={"Lexend"}
                    fontSize={"32px"}
                    lineHeight={"normal"}
                    letterSpacing={"0.22px"}
                    fontWeight={"200"}
                    textAlign={"center"}
                    px={"2rem"}
                >
                    {typewriterText}
                    <span style={{ opacity: showCursor ? 1 : 0, }}>|</span>
                </Text>
            </Box>
            <HoverButton styling={{ marginTop: "20px", width: "a5" }} onClick={scrollHandler} text={"Check out my work"} />
        </Flex>
    );
}

export default HomeText;