import FeaturedProjct from "../components/HomePage/FeaturedProject";
import ShowcaseBox from "../components/HomePage/ShowcaseBox";
import { useEffect } from "react";
import { useRef } from "react";
import { Flex, useMediaQuery } from "@chakra-ui/react";
const HomePage = () => {
    const scrollRef = useRef();

    const [isLargerThan800] = useMediaQuery('(min-width: 800px)')

    const scrollHandler = () => {
        scrollRef.current.scrollIntoView({
            behavior: 'smooth',
        });
    }
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);


    return (<Flex flexDir={"column"}>
        <ShowcaseBox scrollHandler={scrollHandler} scrollRef={scrollRef} isLargerThan800={isLargerThan800}/>
        <FeaturedProjct scrollHandler={scrollHandler} scrollRef={scrollRef} isLargerThan800={isLargerThan800} about={false}/>
    </Flex>)
}

export default HomePage;