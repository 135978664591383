import { Flex, Box, Text } from "@chakra-ui/react"
const CodeBubbleMobile = () => {

    return (
    <Flex flexDir={"row"} align={"center"} justify={"center"} gap={"6vw"} mb={"10px"}>
        <Box
            background={"#F87575"}
            borderRadius={"37px"}
            width={"100px"}
            height={"45px"}
            alignItems={"center"}
            justifyContent={"center"}
            display={"flex"}
            flexShrink={0}
        >
            <Text 
            color={"#3A1F19"} fontFamily={"Lexend"} fontSize={"19px"} fontStyle={"normal"} fontWeight={"400"} lineHeight={"normal"} letterSpacing={"-0.48px"}>
                React.js
            </Text>
        </Box>
        <Box
            background={"rgba(253, 194, 30, 0.69)"}
            borderRadius={"37px"}
            width={"50px"}
            height={"50px"}
            alignItems={"center"}
            justifyContent={"center"}
            display={"flex"}
            flexShrink={0}
        >
            <Text color={"#000"} fontFamily={"Lexend"} fontSize={"19px"} fontStyle={"normal"} fontWeight={"400"} lineHeight={"normal"} letterSpacing={"-0.48px"}>
                JS
            </Text>
        </Box>
        <Box
            background={"#F87575"}
            borderRadius={"37px"}
            width={"100px"}
            height={"45px"}
            alignItems={"center"}
            justifyContent={"center"}
            display={"flex"}
            flexShrink={0}
        >
            <Text color={"#3A1F19"} fontFamily={"Lexend"} fontSize={"19px"} fontStyle={"normal"} fontWeight={"400"} lineHeight={"normal"} letterSpacing={"-0.48px"}>
                Next.js
            </Text>
        </Box>
        <Box
            background={"rgba(253, 194, 30, 0.69)"}
            borderRadius={"37px"}
            width={"50px"}
            height={"50px"}
            alignItems={"center"}
            justifyContent={"center"}
            display={"flex"}
            flexShrink={0}
        >
            <Text color={"#000"} fontFamily={"Lexend"} fontSize={"19px"} fontStyle={"normal"} fontWeight={"400"} lineHeight={"normal"} letterSpacing={"-0.48px"}>
                CSS
            </Text>
        </Box>
    </Flex>)

}

export default CodeBubbleMobile