import { Flex, Text, Box } from "@chakra-ui/react";

const ContactPageText = ({ isLargerThan800 }) => {
    return (
        <Flex
            flexDir={"column"}
            gap={"10px"}
            align={"center"}
            justify={"center"}
            p={isLargerThan800 ? "5px" : "2px"}
        >

            <Text
                color={"#37373F"}
                fontFamily={"Lexend"}
                fontSize={"9vh"}
                fontStyle={"normal"}
                fontWeight={"600"}
                lineHeight={"normal"}
                letterSpacing={"-2px"}
                textAlign="center"
            >
                Contact Me
            </Text>

            <Box w={"60%"} borderTop={"2px solid #37373F"}>

            </Box>

            <Text
                color={"#37373F"}
                fontFamily={"Lexend"}
                fontSize={"3vh"}
                fontStyle={"normal"}
                lineHeight={"normal"}
                letterSpacing={"-2px"}
                textAlign={"center"}
                mx={!isLargerThan800 && "24px"}
            >
                Have a question? Feel free to get in touch at anytime, <br></br> whether for work inqurieres or to just say hello!
            </Text>


        </Flex>
    );

}

export default ContactPageText;