import { Box, Text } from "@chakra-ui/react";

const FeaturedProjectTitle = ({ isLargerThan800, about }) => {

    return (

        <Box width={"100%"} >
            <Text
                fontFamily={"Lexend"}
                fontSize={"18px"}
                fontWeight={"200"}
                _before={{
                    content: '""',
                    display: "inline-block",
                    width: "50px",
                    height: "1px",
                    background: "#37373F",
                    verticalAlign: "top",
                    marginTop: "11px",
                    marginRight: "10px"
                }}
                color={"#37373F"}
                letterSpacing={"-0.48px"}
                textAlign={!isLargerThan800 && "center"}
            >
                {about ? "MY FEATURED PROJECTS" : "MY FEATURED PROJECT"}
            </Text>
        </Box>)

}

export default FeaturedProjectTitle;